import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { get } from 'lodash';

import './WizardForm.css';
import TextQuestion from "./questionComponents/TextQuestion";
import SelectionQuestion from "./questionComponents/SelectionQuestion";
import { QUESTION_TYPES } from "./constants";
import questions from "./config/questions.json";
import design from "./config/design.json";

const WizardForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sortedPages, setSortedPages] = useState([]);
  const [currentPage, setPage] = useState(undefined);
  const [currentPageNum, setPageNum] = useState(1);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [inputAnswers, setInputAnswers] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClose = () => setShowConfirm(false);
  const handleShow = () => setShowConfirm(true);

  const handleOnTextAnswerChange = ({formId, questionText, response}) => {
    const isValid = (response.length > 0);
    setIsNextDisabled(!isValid);

    const newAnswer = {
      questionText: questionText,
      response: response,
      isValid: isValid,
    };

    // Keep the hashmap of each answer, keyed by formId
    setInputAnswers({
      ...inputAnswers,
      [formId]: newAnswer
    });
  }

  const handleOnSelectChange = ({formId, questionText, inputId, boxType, option}) => {
    let response;
    if (boxType === QUESTION_TYPES.RADIO) {
      response = [{
        inputId: inputId,
        option: option,
      }];
    } else {
      const prevAnswers = get(inputAnswers, `${formId}.response`, []);
      const isExisting = prevAnswers.filter(({inputId: filteredInput}) => filteredInput === inputId).length > 0;

      if (isExisting) {
        response = prevAnswers.filter(({inputId: filteredInput}) => filteredInput !== inputId)
      } else {
        response = [...prevAnswers, {
          inputId: inputId,
          option: option,
        }];
      }
    }

    const isValid = response.length > 0;
    setIsNextDisabled(!isValid);

    // get the previous selections and append to it
    const newAnswer = {
      questionText: questionText,
      response: response,
      isValid: isValid,
    };

    // Keep the hashmap of each answer, keyed by formId
    // selection answers will be stored as arrays
    setInputAnswers({
      ...inputAnswers,
      [formId]: newAnswer
    });
  }

  // onMount
  useEffect(() => {
    const initialInputAnswers = {};
    // set initial inputAnswers so that they have empty answers
    questions.forEach(({question, formId, type}) => {
      const response = type === QUESTION_TYPES.TEXT ? '' : [];
      initialInputAnswers[formId] = {
        response: response,
        questionText: question,
      };
    });

    // use questionOrder to sort the questions
    const sortedPages = questions.sort((a, b) => a.questionOrder - b.questionOrder);
    setSortedPages(sortedPages);
    setInputAnswers(initialInputAnswers);
  }, []);

  // update the current page once questionPages are available and currentPageNum changes
  useEffect(() => {
    const newPage = sortedPages[currentPageNum - 1];
    setPage(newPage);

    // determine if page you just clicked on should have next button enabled
    let isDisabled = true;
    if (newPage) {
      const isValid = get(inputAnswers, `${newPage.formId}.isValid`, false);
      isDisabled = !isValid;
    }

    setIsNextDisabled(isDisabled);
  }, [sortedPages, currentPageNum, inputAnswers]);

  const renderCurrentPage = () => {
    const q = currentPage;
    const response = get(inputAnswers, `${q.formId}.response`, '');

    if (q.type === QUESTION_TYPES.TEXT) {
      // Safely extract the response
      return (
        <TextQuestion
          formId={q.formId}
          questionText={q.question}
          response={response}
          placeholder={q.placeholder}
          handleChange={handleOnTextAnswerChange}
        />
      )
    } else if (q.type === QUESTION_TYPES.RADIO) {
      return (
        <SelectionQuestion
          formId={q.formId}
          questionText={q.question}
          response={response}
          handleChange={handleOnSelectChange}
          options={q.options}
          boxType={QUESTION_TYPES.RADIO}
        />
      )
    } else if (q.type === QUESTION_TYPES.CHECKBOX) {
      return (
        <SelectionQuestion
          formId={q.formId}
          questionText={q.question}
          response={response}
          handleChange={handleOnSelectChange}
          options={q.options}
          boxType={QUESTION_TYPES.CHECKBOX}
        />
      )
    }
  }

  const postEmailApi = () => {
    handleClose();
    setIsSubmitting(true);
    fetch(process.env.REACT_APP_EMAIL_SERVER_URL, {
        method: "POST",
        body: JSON.stringify({inputAnswers}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }
    )
    .then((response) => (response.json()))
    .then((response) => {
      if (response.status === 'success') {
        setIsSubmittedSuccessfully(true);
        setErrorMessage('')
      } else if(response.status === 'fail') {
        setErrorMessage(response.error[0])
      }
    })
    .catch((e) => setErrorMessage(e.message))
    .finally(setIsSubmitting(false));
  }

  const nextPage = () => setPageNum((prev) => ++prev);
  const prevPage = () => setPageNum((prev) => --prev);

  return (
    <Container className="wizardWrapper">
      <Col className='mt-4' xs={{ span: 6, offset: 3 }} md={{ span: 2, offset: 5 }}>
        <Image src={design.logoUrl} fluid />
      </Col>
      <Row className='mt-5'>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }}>
          {(errorMessage && !isSubmittedSuccessfully) && <p>Error: { errorMessage }. Please try again.</p>}
        </Col>
      </Row>
      <Row>
        <Col className="wizardForm" xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }}>
          { !isSubmittedSuccessfully ?
            <Form onSubmit={e => { e.preventDefault(); }}>
              <div className='mb-5'>
                { currentPage && renderCurrentPage() }
              </div>
              { currentPageNum !== 1 &&
                <Button
                  onClick={() => prevPage()}
                  className="float-left"
                  variant="primary"
                >
                  Previous
                </Button>
              }
              { currentPageNum !== questions.length ?
                <Button
                  onClick={() => nextPage()}
                  className="float-right"
                  variant="primary"
                  disabled={isNextDisabled}
                >
                  Next
                </Button>
                :
                <Button
                  onClick={() => handleShow()}
                  className="float-right"
                  variant="primary"
                  disabled={isSubmitting || isNextDisabled}
                >
                  Submit
                </Button>
              }
            </Form>
            :
            <p>Thank you for your time! We are looking forward to seeing you, and we will contact you regarding the answers of this form if necessary.</p>
          }
        </Col>
      </Row>
      <Modal animation={false} show={showConfirm} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please confirm your answers before submitting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            Object.keys(inputAnswers).map((form) => {
              const { questionText, response } = inputAnswers[form];
              if (Array.isArray(response)) {
                return (
                  <div key={form}>
                    <h5 className='mt-3'>{questionText}</h5>
                    {response.length > 0 ?
                      response.map((r) => (
                        <p key={r.option}>{r.option}</p>
                      ))
                      :
                      <p>No options were selected</p>
                    }
                  </div>
                )
              } else {
                return (
                  <div key={form}>
                    <h5 className='mt-3'>{questionText}</h5>
                    <p>{response}</p>
                  </div>
                )
              }
            })
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Back
          </Button>
          <Button variant="primary" onClick={() => postEmailApi()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default WizardForm;
