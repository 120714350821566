import React from "react";
import Form from 'react-bootstrap/Form';

const SelectionQuestion = ({formId, questionText, options, response, boxType, handleChange}) => {
  return (
    <Form.Group controlId={formId}>
      <Form.Label className='mb-3'>{questionText}</Form.Label>
			{ options.map((option) => {
				const id = `${formId}-${option}-${boxType}`;

				// check if response array contains an object with this input key formed by above id
				const isSelected = response ? response.filter(({inputId: filteredInput}) => filteredInput === id).length > 0 : false;

				return (
					<Form.Check
						className='mt-3'
						key={id}
						id={id}
						type={boxType}
						label={option}
						name={questionText}
						checked={isSelected}
						onChange={() => handleChange({formId, questionText, inputId: id, boxType, option})}
					/>
				)
			})}
    </Form.Group>
  );
}

export default SelectionQuestion;