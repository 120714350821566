import React from "react";
import Form from 'react-bootstrap/Form';

const TextQuestion = ({formId, questionText, placeholder, response, handleChange}) => {
  return (
    <Form.Group controlId={formId}>
      <Form.Label>{questionText}</Form.Label>
      <Form.Control
        onChange={(e) => handleChange({formId, questionText, response: e.target.value})}
        type="text"
        placeholder={placeholder}
        value={response}
      />
    </Form.Group>
  );
}

export default TextQuestion;